.container {
  &:hover {
    .dropdown {
      .menuItems {
        display: block;
      }
    }
  }
  .titleLink {
    display: flex;
    align-items: center;
    margin-top: -6px;
    padding: 6px;
    color: var(--light-text);

    &:hover {
      background-color: rgba(255, 255, 255, 0.3490196078);
      border-radius: var(--border-radius-md);
    }
  }

  .dropdown {
    position: absolute;
    top: 85%;
    // bottom: -220%;
    left: 0;
    width: fit-content;

    .spacer {
      height: 5px;
      background: transparent;
    }

    .menuItems {
      display: none;
      width: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
      list-style: none;
      background: var(--light-background);
      box-shadow: var(--box-shadow-light);

      &:hover {
        display: block;
      }

      .menuItem {
        min-width: 100%;
        width: max-content;
        max-width: 400px;
        margin: 0;
        padding: 0;
        text-align: left;
        font-weight: 500;
        transition: var(--transition-md);

        &:hover {
          background: var(--dark-background);
        }

        .menuItemLink {
          display: block;
          width: 100%;
          color: var(--dark-text);
          padding: var(--spacing-xs) var(--spacing-sm);
          transition: var(--transition-md);

          &:hover {
            color: var(--light-text) !important;
          }
        }
      }
    }
  }
}
